.portfolio-image-container{
    width:100%;
    height:180px;
    overflow:hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.portfolio-image{
    width:100%
}